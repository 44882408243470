<template>
  <div>
    <div class="row justify-content-between align-items-end">
      <div class="col-6">
        <label for="">Désignation</label>
        <input
          type="text"
          v-model="customer_type.fullName"
          class="form-control"
        />
      </div>
      <div class="col-6">
        <button class="btn btn-success" @click="save(customer_type)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customer_type: {},
    };
  },
  computed: {},
  methods: {
    async save(data) {
      await this.$store.dispatch("customer/storeType", data);
    },
    async empty() {
      this.$store.commit("customer/setCustomerType", {});
    },
  },
  beforeMount() {
    this.$store.commit("customer/setCustomerType", {});
  },
};
</script>
